import baseCRUDService from '../common/baseService'

const API_PATH = 'node/config'

const baseService = new baseCRUDService(API_PATH)

// Assign Node
const createNode = async (nodeData, token, ...params) => {
  return baseService.createItem(nodeData, token)
}


const nodeService = {
  createNode,
}

export default nodeService
