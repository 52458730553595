import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaPlus, FaList } from 'react-icons/fa'
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


function CompanyMenu() {

  const { user } = useSelector((state) => state.auth)

  return (
    <>
    <br/>
      <section className='heading'>
        <h1>Your company: {user && user.username}</h1>
      </section>
    <div className='menupage'>
      <Container>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/company/list'>
            <FaList /><div className='btn-text'>List companies</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/company/add'>
            <FaPlus /><div className='btn-text'>Add company</div>
          </Link>
        </Row>
      </Container>
    </div>
    </>
  )
}

export default CompanyMenu
