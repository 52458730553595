import axios from 'axios'

const BASE_API_URL = '/api/'

class baseCRUDService {
  constructor(api_path) {
    if(api_path.includes(':company_id')) {
      this.company_id = true
    }
    this.store_item = api_path
    this.api_url = BASE_API_URL + api_path + '/';
  }

  constructPath = (path, company_id) => {
    if(this.company_id && company_id) {
      return this.api_url.replace(':company_id', company_id) + path
    }
    return this.api_url + path
  }


  // Create new item
  createItem = async (itemData, token, path = '', company_id = undefined) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.post(this.constructPath(path, company_id), itemData, config)
    return response.data
  }

  // Get items
  getItems = async (token, path = '', company_id = undefined) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.get(this.constructPath(path, company_id), config)
    return response.data
  }

  // Get item
  getItem = async (itemId, token, path = '', company_id = undefined) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { itemId },
    }
    const response = await axios.get(this.constructPath(path, company_id), config)
    return response.data
  }

  // Delete item
  deleteItem = async (itemId, token, path = '', company_id = undefined) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { id: itemId },
    }
    const response = await axios.delete(this.constructPath(path, company_id), config)
    return response.data
  }

  // Edit item
  editItem = async (item, token, path = '', company_id = undefined) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.put(this.constructPath(path, company_id), item, config)
    return response.data
  }

  clear = async () => {
    console.log('itemsevicebe bejöttünk')
    localStorage.removeItem(this.store_item)
  }

}

export default baseCRUDService
