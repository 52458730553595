import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/users/userSlice'
import boatReducer from '../features/boats/boatSlice'
import companyReducer from '../features/companies/companySlice'
import nodeReducer from '../features/node/nodeSlice'
import cuReducer from '../features/cu/cuSlice'
import influxReducer from '../features/influx/influxSlice'
import syncReducer from '../features/sync/syncSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    boats: boatReducer,
    boatStatus: influxReducer,
    sync: syncReducer,
    companies: companyReducer,
    nodes: nodeReducer,
    coreunits: cuReducer,
  },
})
