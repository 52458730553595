import baseCRUDService from '../common/baseService'

const API_PATH = 'sync'

const baseService = new baseCRUDService(API_PATH)

// POST sync boat
const syncBoat = async (path, token, company_id, ...params) => {
  return baseService.createItem({}, token, path)
}

const clear = async (...params) => {
  return baseService.clear()
}

const influxService = {
  syncBoat,
  clear,
}

export default influxService
