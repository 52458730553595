import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaWarehouse } from 'react-icons/fa'
import { createCompany, editCompany, reset } from '../features/companies/companySlice'
import Spinner from '../components/Spinner'

function CompanyForm() {
  let { company_id } = useParams();
  const [formData, setFormData] = useState({
    company_shortname: '',
    company_name: '',
    company_address: '',
    admin_user_id: '',
  })

  const {  company_shortname,
           company_name,
           company_address,
           admin_user_id,
           } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { companies, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.companies
  )

  useEffect(() => {
    if(company_id && companies.length > 0) { 
      companies.map((company) => { 
        if(company.id === company_id) { 
          setFormData({
            company_shortname: company.company_shortname,
            company_name: company.company_name,
            company_address: company.company_address,
            admin_user_id: company.admin_user_id,
          })
        }
        })
    }
    if (isError) {
      toast.error(message)
    }
    if (isSuccess && company_id) {
      toast.success("Company edited")
      navigate('/company/list')
    }
    if (isSuccess && !company_id) {
      toast.success("Company created")
      navigate('/company/list')
    }

    dispatch(reset())
  }, [companies, isError, isSuccess, message, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const companyData = {
      id: company_id ? company_id : null,
      company_shortname,
      company_name,
      company_address,
      admin_user_id,
    }
    company_id ? dispatch(editCompany(companyData)) : dispatch(createCompany(companyData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className='heading'>
        <h1>
          <FaWarehouse /> Add company
        </h1>
      </section>

      <section className='form'>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='text'
              disabled
              readOnly
              className='form-control'
              id='id'
              name='id'
              placeholder='Company id will be assigned by the server'
              value={company_id ? company_id : ''}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='company_shortname'
              name='company_shortname'
              value={company_shortname}
              placeholder='Enter the company short name'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='company_name'
              name='company_name'
              value={company_name}
              placeholder='Enter the complete company name'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='company_address'
              name='company_address'
              value={company_address}
              placeholder='Enter the company address'
              onChange={onChange}
            />
          </div>
          {/* <div className='form-group'>
            <input
              type='number'
              className='form-control'
              id='admin_user_id'
              name='admin_user_id'
              value={admin_user_id}
              placeholder='Pick admin user id'
              onChange={onChange}
            />
          </div> */}
          <div className='form-group'>
            <button type='submit' className='btn btn-block'>
              Submit
            </button>
          </div>
        </form>
      </section>
    </>
  )
}

export default CompanyForm
