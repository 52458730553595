import { FaSignInAlt, FaUser, FaLaptopCode, FaWarehouse, FaShip, FaScrewdriver } from 'react-icons/fa'
import { useNavigate, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const iconSize = 25
const activeTab = {
  'boats': false,
  'setup': false,
  'manufacture': false,
  'company': false,
  'settings': false
}

function HeaderMobile({ user }) {
  const navigate = useNavigate()
  const location = useLocation();
  const [tab, setTab] = useState(activeTab);

  const handleClick = (path) => {
    let updatedValue = {[path]:true};
    setTab( tab => ({
        ...activeTab,
        ...updatedValue
      }));
    navigate(path, { replace: true })
  }
  useEffect(() => {
    let updatedValue = {['boats']:true};
    setTab( tab => ({
        ...activeTab,
        ...updatedValue
      }));
  }, []);

  return (
    <>
    <div className='appbar'>
        {/* <Link to='/'>
          <img className="logo" src={logo} alt="Logo" style={{height: '0.5rem'}}/> 
        </Link> */}
        {user ? (
          <>
          <Row>
            <Col className='menuicon' onClick={() => {handleClick('boats')}}>
              <FaShip size={iconSize} style={{color: tab['boats'] ? "var(--light-orange-color)" : "grey"}}/>
            </Col>
            <Col className='menuicon' onClick={() => {handleClick('setup')}}>
              <FaScrewdriver size={iconSize}  style={{color: tab['setup'] ? "var(--light-orange-color)" : "grey"}}/>
            </Col>
            <Col className='menuicon' onClick={() => {handleClick('manufacture')}}>
              <FaLaptopCode size={iconSize} style={{color: tab['manufacture'] ? "var(--light-orange-color)" : "grey"}}/>
            </Col>
            <Col className='menuicon' onClick={() => {handleClick('company')}}>
              <FaWarehouse size={iconSize} style={{color: tab['company'] ? "var(--light-orange-color)" : "grey"}}/>
            </Col>
            <Col className='menuicon' onClick={() => {handleClick('settings')}}>
              <FaUser size={iconSize} style={{color: tab['settings'] ? "var(--light-orange-color)" : "grey"}}/>
            </Col>
          </Row>
          </>
        ) : (
          <>
            <Row>
              <a href='/login'>
                <Col className='menuicon' style={{color: "var(--light-orange-color)"}}>
                  <FaSignInAlt /> Login
                </Col>
              </a>
            </Row>
          </>
        )}
      </div>
          </>
  )
}

export default HeaderMobile
