import cuService from './cuService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'coreunits'

// Get companies from localStorage
const coreunits = JSON.parse(localStorage.getItem(ITEM_NAME))

const initialState = {
  coreunits: coreunits ? coreunits : {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseService = new baseCRUDSlice(ITEM_NAME, initialState)

// Create new token for CU
export const createCUToken = baseService.createItem(cuService.createCUToken)

// Validate CU creation
export const validateCU = baseService.getItem(cuService.validateCU)


export const cuSlice = baseService.itemSlice(ITEM_NAME, createCUToken, undefined, validateCU, undefined, undefined, undefined)

export const { reset } = cuSlice.actions
export default cuSlice.reducer
