import Steps from 'rsuite/Steps';
import 'rsuite/dist/rsuite.min.css';

const styles = {
  width: '400px',
//   height: '600px',
  display: 'inline-table',
  verticalAlign: 'top'

};

function ProgressSteps({ steps, current, currentStatus }) {
    // console.log(steps)
    return (
        <Steps current={current} vertical style={styles} currentStatus={currentStatus ? currentStatus: undefined}>
        { steps && steps.map((element,index) => (
            <Steps.Item key={index} title={element} />
        ))}
    </Steps>
)
}

export default ProgressSteps