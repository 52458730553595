import baseCRUDService from '../common/baseService'

const API_PATH = 'users'

const baseService = new baseCRUDService(API_PATH)

// Create new user
const createUser = async (userData, token, ...params) => {
  return baseService.createItem(userData, token, 'add')
}

// Get users
const getUsers = async (token, ...params) => {
  return baseService.getItems(token)
}

// Get user
const getUser = async (user_id, token, ...params) => {
  return baseService.getItem(user_id, token)
}

// Delete user
const deleteUser = async (userId, token, ...params) => {
  return baseService.deleteItem(userId, token)
}

// Edit user
const editUser = async (user, token, ...params) => {
  return baseService.editItem(user, token)
}

const clear = async (...params) => {
  return baseService.clear()
}

const userService = {
  createUser,
  getUsers,
  getUser,
  editUser,
  deleteUser,
  clear,
}

export default userService
