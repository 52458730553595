import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FaPlus, FaList, FaSignOutAlt } from 'react-icons/fa'
// import Container from 'react-bootstrap/Container';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { logout, reset } from '../features/auth/authSlice'


function UserMenu() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/')
  }

  return (
    <>
      <br/>
    <section className='heading'>
      <h1>Welcome {user && user.username}</h1>
    </section>
    <div className='menupage'>
      <Container>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/users'>
            <FaList /><div className='btn-text'>List users</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/users/add'>
            <FaPlus /><div className='btn-text'>Add user</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row className='settingsButton'>
             <Link className={classNames('btn','btn-center')} onClick={onLogout}>
                <FaSignOutAlt /><div className='btn-text'>Logout</div>
              </Link>
        </Row>
      </Container>
    </div>
    </>
  )
}

export default UserMenu
