import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FaPlus } from 'react-icons/fa'
import Spinner from '../components/Spinner'
import { getBoats, deleteBoat, reset } from '../features/boats/boatSlice'
import { logout } from '../features/auth/authSlice'
import ActionButton from '../components/common/ActionButton'
import BoatStatus from '../components/BoatStatus'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';


function Dashboard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { boats, isLoading, isError, message } = useSelector(
    (state) => state.boats
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (!user || message === 'Not authorized') {
      dispatch(logout())
      navigate('/login')
    }
    if (boats.length === 0 && !isError) {
      dispatch(getBoats())
    }
    dispatch(reset())

  }, [boats, navigate, isError, message, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
     <br/>
      <section className='heading'>
        <Row>
          <Col xs={11}>
            <h1>Welcome {user && user.username}</h1>
          </Col>
          <Col xs={1}>
            <button className='btn' onClick={() => navigate(`/boats/add`)}>
              <FaPlus />
            </button>
          </Col>
        </Row>
      </section>
      <div style={{ position: 'auto !important'}}>
      <Table responsive="sm" >
        <thead>
          <tr>
          <th scope="col">id</th>
          <th scope="col">Name</th>
          <th scope="col">Reg_num</th>
          <th scope="col">Manage</th>
          </tr>
        </thead>
        <tbody>
            {boats.map((boat) => (
            <tr key={boat.id}>
              <th scope="row">{boat.id}</th>
              <td>{boat.name}</td>
              <td>{boat.reg_num}</td>
              <td>
                <Row>
                  <Col>
                    <ActionButton key={boat.id + "_1"} type='faPen' onClick={() => navigate(`/boats/${boat.id}`)} color='blue' />
                  </Col>
                  <Col>
                    <ActionButton key={boat.id + "_2"} type='faTrashCan' onClick={() => dispatch(deleteBoat(boat.id))} color='red' />
                  </Col>
                </Row>
              </td>
            </tr>
            ))}
        </tbody>
        </Table>
        </div>
        <BoatStatus/>
    </>
  )
}

export default Dashboard
