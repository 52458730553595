import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../components/Spinner'
import { toast } from 'react-toastify'
import { getUsers, deleteUser, editUser, reset } from '../features/users/userSlice'
import ActionButton from '../components/common/ActionButton'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { logout } from '../features/auth/authSlice'


function Users() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { users, isLoading, isError, message } = useSelector(
    (state) => state.users
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (!user || !users || message === 'Not authorized') {
      dispatch(logout())
      navigate('/login')
    }
    if (users?.length === 0) {
      dispatch(getUsers())
    }

    return () => {
      dispatch(reset())
    }
  }, [users, navigate, isError, message, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {/* <UserForm /> */}
      {/* <section className='content'> */}
      <div  style={{ position: 'auto !important'}}>
      <Table responsive="sm" >
        <thead>
            <tr>
            <th scope="col">id</th>
            <th scope="col">username</th>
            <th scope="col">Email</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Manage</th>
            </tr>
        </thead>
        <tbody>
            {users && users.map((user) => (
            <tr key={user.id}>
                <th scope="row">{user.id}</th>
                <td>{user.username}</td>
                <td>{user.email}</td>
                {/* <td>{user.username}</td> */}
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>
                    <Row>
                        <Col>
                            <ActionButton key={user.id + "_1"} type='faPen' onClick={() => navigate(`/users/${user.id}`)} color='blue' />
                        </Col>
                        <Col>
                            <ActionButton key={user.id + "_2"} type='faTrashCan' onClick={() => dispatch(deleteUser(user.id))} color='red' />
                        </Col>
                    </Row>
                </td>
                {/*faSailboat faSatellite*/}
            </tr>
            ))}
        </tbody>
        </Table>
        </div>
      {/* </section> */}
    </>
  )
}

export default Users
