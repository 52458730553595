import baseCRUDService from '../common/baseService'
import axios from 'axios'

const API_PATH = 'coreunit/config'
let LOCAL_API_URL = 'http://local.sailync.hu/api/'
if(process.env.NODE_ENV === 'production'){
  LOCAL_API_URL = 'http://local.sailync.hu/api/'
}

const baseService = new baseCRUDService(API_PATH)

// Deliver token locally
const deliverToken = async (cuData, token, ...params) => {
  const config = {
    params: {
      qr_uid: cuData.cu_uid,
      token: cuData.token
    }
  }
  const response = await axios.post(LOCAL_API_URL + 'assign_token', null, config)
  return response.data
}


// Create new token for CU
const createCUToken = async (cuData, token, ...params) => {
  return baseService.createItem(cuData, token)
}

// Validate CU creation
const validateCU = async (cuData, token, ...params) => {
  return baseService.getItem(cuData, token)
}


const cuService = {
  createCUToken,
  validateCU,
  deliverToken,
}

export default cuService
