import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FaSync } from 'react-icons/fa'
import { useState } from 'react'
import moment from 'moment'
import Spinner from '../components/Spinner'
import ActionButton from '../components/common/ActionButton'
import { getStatus, reset } from '../features/influx/influxSlice'
import { syncBoat, reset as resetSync } from '../features/sync/syncSlice'
import { logout } from '../features/auth/authSlice'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

function BoatStatus() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [lastRefreshedDate, setLastRefreshedDate] = useState()
  const [lastRefreshed, setLastRefreshed] = useState('')

  const { user } = useSelector((state) => state.auth)
  const { bStatus, isLoading, isError, message } = useSelector(
    (state) => state.boatStatus
  )
  const {
    sync,
    isLoading: isSyncLoading,
    isError: isSyncError,
    isSuccess: isSyncSuccess,
    message: syncMessage,
  } = useSelector((state) => state.sync)

  const syncMakka = () => {
    dispatch(syncBoat('mikkamakka'))
  }
  const syncVentus = () => {
    dispatch(syncBoat('ventus'))
  }
  const refreshBoatStatus = () => {
    dispatch(getStatus())
    setLastRefreshedDate(Date.now())
  }
  useEffect(() => {
    if (isError) {
      toast.error(message)
    }
    if (isSyncError) {
      toast.error(syncMessage)
    }
    if (isSyncSuccess) {
      if (sync?.response?.includes('successful')) {
        toast.success(sync?.response)
      } else {
        toast.info(sync?.response)
      }
      dispatch(resetSync())
    }

    if (
      !user ||
      message === 'Not authorized' ||
      syncMessage === 'Not authorized'
    ) {
      dispatch(logout())
      navigate('/login')
    }
    if (bStatus.length === 0 && !isError) {
      refreshBoatStatus()
    }
    dispatch(reset())
    const timerId = setInterval(refreshTimeAgo, 1000)
    return function cleanup() {
      clearInterval(timerId)
    }
  }, [
    bStatus,
    navigate,
    isError,
    message,
    dispatch,
    lastRefreshed,
    sync,
    isSyncError,
    syncMessage,
    isSyncSuccess,
  ])

  if (isLoading) {
    return <Spinner />
  }

  const refreshTimeAgo = () => {
    setLastRefreshed(
      moment.utc(lastRefreshedDate).local().startOf('seconds').fromNow()
    )
  }

  const onlineIcon = (time) => {
    if (moment().diff(moment.utc(time).local(), 'minutes') < 5) {
      return <ActionButton key={time} type="faSignal" color="green" />
    } else {
      return <ActionButton key={time} type="faTimes" color="red" />
    }
  }

  return (
    <>
      <br />
      <section className="heading">
        <Row>
          <Col xs={10}>
            <h1>Welcome {user && user.username}</h1>
          </Col>
          <Col xs={1}>
            <button className="btn" onClick={() => refreshBoatStatus()}>
              <FaSync />
            </button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h5>Last refreshed: {lastRefreshed}</h5>
          </Col>
        </Row>
      </section>
      <div style={{ position: 'auto !important' }}>
        <Table responsive="sm">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Voltage</th>
              <th scope="col">Last seen</th>
              <th scope="col">Online</th>
            </tr>
          </thead>
          <tbody>
            {bStatus.map((boat, index) => (
              <tr key={index}>
                <th scope="row">{boat.boat.name}</th>
                <td>{boat.boat.value} V</td>
                <td>
                  {moment
                    .utc(boat.boat.time)
                    .local()
                    .startOf('seconds')
                    .fromNow()}
                </td>
                <td>{onlineIcon(boat.boat.time)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <section className="others">
        <button
          className="btn"
          onClick={() => syncMakka()}
          disabled={isSyncLoading}
        >
          Sync Mikkamakka data
        </button>
        <button
          className="btn"
          onClick={() => syncVentus()}
          disabled={isSyncLoading}
        >
          Sync Ventus data
        </button>
      </section>
    </>
  )
}

export default BoatStatus
