import boatService from './boatService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'boats'

// Get boats from localStorage
const boats = JSON.parse(localStorage.getItem('boats'))

const initialState = {
  boats: boats ? boats : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseSlice = new baseCRUDSlice(ITEM_NAME, initialState)

// Create new boat
export const createBoat = baseSlice.createItem(boatService.createBoat)

// Get boats
export const getBoats = baseSlice.getItems(boatService.getBoats)

// Get boat
export const getBoat = baseSlice.getItem(boatService.getBoat)

// Delete boat
export const deleteBoat = baseSlice.deleteItem(boatService.deleteBoat)

// Delete boat
export const editBoat = baseSlice.editItem(boatService.editBoat)

// Logout
export const clear = baseSlice.clearItem(boatService.clear)

export const boatSlice = baseSlice.itemSlice(ITEM_NAME, createBoat, getBoats, getBoat, editBoat, deleteBoat, clear)

export const { reset } = boatSlice.actions
export default boatSlice.reducer
