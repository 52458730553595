import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaShip } from 'react-icons/fa'
import { createBoat, editBoat, reset } from '../features/boats/boatSlice'
import Spinner from '../components/Spinner'

function BoatForm() {
  let { boat_id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    reg_num: '',
  })

  const {   name,
            reg_num,
           } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { boats, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.boats
  )

  useEffect(() => {
    if(boat_id && boats.length > 0) { 
      boats.map((boat) => { 
        if(boat.id === boat_id) { 
          setFormData({
            name: boat.name,
            reg_num: boat.reg_num,
          })
        }
        })
    }
    if (isError) {
      toast.error(message)
    }
    if (isSuccess && boat_id) {
      toast.success("Boat edited")
      navigate('/boats')
    }
    if (isSuccess && !boat_id) {
      toast.success("Boat created")
      navigate('/boats')
    }

    dispatch(reset())
  }, [boats, isError, isSuccess, message, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const boatData = {
      id: boat_id ? boat_id : null,
      name,
      reg_num,
    }
    boat_id ? dispatch(editBoat(boatData)) : dispatch(createBoat(boatData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className='heading'>
        <h1>
          <FaShip /> Add boat
        </h1>
      </section>

      <section className='form'>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='text'
              disabled
              readOnly
              className='form-control'
              id='boat_id'
              name='boat_id'
              placeholder='Boat id will be assigned by the server'
              value={boat_id ? boat_id : ''}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='name'
              name='name'
              value={name}
              placeholder='Enter the boatname'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='reg_num'
              name='reg_num'
              value={reg_num}
              placeholder='Enter the boat registration number'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <button type='submit' className='btn btn-block'>
              Submit
            </button>
          </div>
        </form>
      </section>
    </>
  )
}

export default BoatForm
