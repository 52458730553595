import influxService from './influxService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'bStatus'

// Get boat status from localStorage
const bStatus = JSON.parse(localStorage.getItem('boatStatus'))

const initialState = {
  bStatus: bStatus ? bStatus : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseSlice = new baseCRUDSlice(ITEM_NAME, initialState)

// Get status
export const getStatus = baseSlice.getItems(influxService.getStatus)

// Logout
export const clear = baseSlice.clearItem(influxService.clear)

export const influxSlice = baseSlice.itemSlice(ITEM_NAME, undefined, getStatus, undefined, undefined, undefined, clear)

export const { reset } = influxSlice.actions
export default influxSlice.reducer
