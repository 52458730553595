import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Spinner({ message }) {
  return (
    <div className='loadingSpinnerContainer'>
      <Col>
        <Row className={'justify-content-center'}>
          <div className='loadingSpinner'></div>
        </Row>
        <Row>
          {message ? <h3>{message}</h3> : <></>}
        </Row>
      </Col>
    </div>
  )
}

export default Spinner
