import userService from './userService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'users'

// Get users from localStorage
const users = JSON.parse(localStorage.getItem('users'))

const initialState = {
  users: users ? users : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseSlice = new baseCRUDSlice(ITEM_NAME, initialState)

// Create new user
export const createUser = baseSlice.createItem(userService.createUser)

// Get users
export const getUsers = baseSlice.getItems(userService.getUsers)

// Get users
export const getUser = baseSlice.getItem(userService.getUser)

// Delete user
export const deleteUser = baseSlice.deleteItem(userService.deleteUser)

// Delete user
export const editUser = baseSlice.editItem(userService.editUser)

// Logout
export const clear = baseSlice.clearItem(userService.clear)

export const userSlice = baseSlice.itemSlice(ITEM_NAME, createUser, getUsers, getUser, editUser, deleteUser, clear)

export const { reset } = userSlice.actions
export default userSlice.reducer
