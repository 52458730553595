import baseCRUDService from '../common/baseService'

const API_PATH = 'company'

const baseService = new baseCRUDService(API_PATH)

// Create new company
const createCompany = async (companyData, token, ...params) => {
  return baseService.createItem(companyData, token)
}

// Get companies
const getCompanies = async (token, ...params) => {
  return baseService.getItems(token, 'all')
}

// Get company
const getCompany = async (companyId, token, ...params) => {
  return baseService.getItems(companyId, token)
}

// Delete company
const deleteCompany = async (companyId, token, ...params) => {
  return baseService.deleteItem(companyId, token)
}

// Edit company
const editCompany = async (company, token, ...params) => {
  return baseService.editItem(company, token)
}

const clear = async (...params) => {
  return baseService.clear()
}

const companyService = {
  createCompany,
  getCompanies,
  getCompany,
  editCompany,
  deleteCompany,
  clear,
}

export default companyService
