
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../../features/auth/authSlice'
import Spinner from '../Spinner'

function FormLogin() { 
    const [formData, setFormData] = useState({
        username: '',
        password: '',
      })
    
      const { username, password } = formData
    
      const navigate = useNavigate()
      const dispatch = useDispatch()
    
      const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
      )
    
      useEffect(() => {
        if (isError) {
          toast.error(message)
        }
    
        if (isSuccess || user) {
          navigate('/')
        }
    
        dispatch(reset())
      }, [user, isError, isSuccess, message, navigate, dispatch])
    
      const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }
    
      const onSubmit = (e) => {
        e.preventDefault()
    
        const userData = {
          username,
          password,
        }
    
        dispatch(login(userData))
      }
    
      if (isLoading) {
        return <Spinner />
      }
    return (
        <section className='form'>
            <form onSubmit={onSubmit}>
                <div className='form-group'>
                    <input
                    type='text'
                    className='form-control'
                    id='username'
                    name='username'
                    value={username}
                    placeholder='Enter your username'
                    onChange={onChange}
                    />
                </div>
                <div className='form-group'>
                    <input
                    type='password'
                    className='form-control'
                    id='password'
                    name='password'
                    value={password}
                    placeholder='Enter password'
                    onChange={onChange}
                    />
                </div>

                <div className='form-group'>
                    <button type='submit' className='btn btn-block'>
                    Submit
                    </button>

                </div>
            </form>
            {/* <div className="alert alert-danger" role="alert">
              This is a primary alert—check it out!
            </div> */}
        </section>
        
  )
}
  
  
export default FormLogin

 