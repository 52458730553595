import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaUser } from 'react-icons/fa'
import { createUser, editUser, reset } from '../features/users/userSlice'
import Spinner from '../components/Spinner'

function UserForm() {
  let { user_id } = useParams();
  let edited = false;
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    password2: '',
    role_id: 30,
  })

  const {  username,
           email,
           first_name,
           last_name,
           role_id,
           password,
           password2 } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { users, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.users
  )

  useEffect(() => {

    if(user_id && users.length > 0) { 
      users.map((user) => { 
        if(user.id === user_id) { 
          setFormData({
            username: user.username,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            role_id: user.role_id,
          })
        }
        })
    }

    if (isError) {
      toast.error(message)
    }
    
    if (isSuccess && user_id) {
      toast.success("User edited")
      navigate('/users')
    }
    if (isSuccess && !user_id) {
      toast.success("User created")
      navigate('/users')
    }

    dispatch(reset())
  }, [users, isError, isSuccess, message, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    edited = true;
    if (password !== password2) {
      toast.error('Passwords do not match')
    } else {
      const userData = {
        id: user_id ? user_id : null,
        username,
        email,
        first_name,
        last_name,
        role_id,
        password,
      }
      user_id ? dispatch(editUser(userData)) : dispatch(createUser(userData))
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className='heading'>
        <h1>
          <FaUser /> Add user
        </h1>
        <p>Add new user</p>
      </section>

      <section className='form'>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='text'
              disabled
              readOnly
              className='form-control'
              id='id'
              name='id'
              placeholder='User id will be assigned by the server'
              value={user_id ? user_id : ''}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='username'
              name='username'
              value={username}
              placeholder='Enter your username'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='email'
              className='form-control'
              id='email'
              name='email'
              value={email}
              placeholder='Enter your email'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='first_name'
              name='first_name'
              value={first_name}
              placeholder='Enter your First name'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='last_name'
              name='last_name'
              value={last_name}
              placeholder='Enter your last name'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
          <label htmlFor="role_id">Select user type:</label>
            <select 
              name="role_id"
              id="role_id"
              className='form-control'
              value={role_id}
              onChange={onChange}
              >
              <option value="10">Manufacture user</option>
              <option value="20">Company Admin</option>
              <option value="30">Normal user</option>
            </select>
          </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              id='password'
              name='password'
              value={password}
              placeholder='Enter password'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              id='password2'
              name='password2'
              value={password2}
              placeholder='Confirm password'
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <button type='submit' className='btn btn-block'>
              Submit
            </button>
          </div>
        </form>
      </section>
    </>
  )
}

export default UserForm
