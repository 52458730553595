import companyService from './companyService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'company'

// Get companies from localStorage
const companies = JSON.parse(localStorage.getItem('companies'))

const initialState = {
  companies: companies ? companies : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseService = new baseCRUDSlice(ITEM_NAME, initialState)

// Create new company
export const createCompany = baseService.createItem(companyService.createCompany)

// Get companies
export const getCompanies = baseService.getItems(companyService.getCompanies)

// Get company
export const getCompany = baseService.getItem(companyService.getCompany)

// Delete company
export const deleteCompany = baseService.deleteItem(companyService.deleteCompany)

// Delete company
export const editCompany = baseService.editItem(companyService.editCompany)

// Logout
export const clear = baseService.clearItem(companyService.clear)

export const companySlice = baseService.itemSlice('companies', createCompany, getCompanies, getCompany, editCompany, deleteCompany, clear)

export const { reset } = companySlice.actions
export default companySlice.reducer
