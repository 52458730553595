import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { FaWrench } from 'react-icons/fa'
// import Container from 'react-bootstrap/Container';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';


function SetupMenu() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)


  return (
    <>
      <br/>
    <section className='heading'>
      <h1>Welcome {user && user.username}</h1>
    </section>
    <div className='menupage'>
      <Container>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/config_cu'>
            <FaWrench /><div className='btn-text'>Config CoreUnit</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/config_node'>
            <FaWrench /><div className='btn-text'>Config Node</div>
          </Link>
        </Row>
      </Container>
    </div>
    </>
  )
}

export default SetupMenu
