import nodeService from './nodeService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'nodes'

// Get companies from localStorage
const nodes = JSON.parse(localStorage.getItem(ITEM_NAME))

const initialState = {
  nodes: nodes ? nodes : [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseService = new baseCRUDSlice(ITEM_NAME, initialState)

// Config a Node
export const configNode = baseService.createItem(nodeService.createNode)


export const nodeSlice = baseService.itemSlice(ITEM_NAME, configNode, undefined, undefined, undefined, undefined, undefined)

export const { reset } = nodeSlice.actions
export default nodeSlice.reducer
