import { FaSignInAlt, FaSignOutAlt, FaUser, FaLaptopCode, FaWarehouse, FaShip, FaScrewdriver } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo_text_bw_vector.svg';

const iconSize = 25

function HeaderDesktop({ user, onLogoutClick }) {
  
  const navigate = useNavigate()
  const handleClick = (path) => {
    navigate(path, { replace: true })
  }

  return (
    <header className='header'>
        <Link to='/'>
          <img className="logo" src={logo} alt="Logo" /> 
        </Link>
      <ul>
        {user ? (
          <>
            <li>
              <button className='btn' onClick={() => handleClick('boats')}>
               <FaShip size={iconSize}/>
              </button>
            </li>

            <li>
              <button className='btn' onClick={() => handleClick('setup')}>
               <FaScrewdriver size={iconSize}/>
              </button>
            </li>

            <li>
              <button className='btn' onClick={() => handleClick('manufacture')}>
               <FaLaptopCode size={iconSize}/>
              </button>
            </li>

            <li>
              <button className='btn' onClick={() => handleClick('company')}>
               <FaWarehouse size={iconSize}/>
              </button>
            </li>

            <li>
              <button className='btn' onClick={() => handleClick('settings')}>
               <FaUser size={iconSize}/>
              </button>
            </li>
            <li>
              <button className='btn' onClick={onLogoutClick}>
                <FaSignOutAlt /><div className='btn-text'>Logout</div>
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link className='btn' to='/login'>
                <FaSignInAlt /><div className='btn-text'>Login</div>
              </Link>
            </li>
          </>
        )}
      </ul>
    </header>
  )
}

export default HeaderDesktop
