import { Link } from 'react-router-dom'
import { FaPlus, FaList,  } from 'react-icons/fa'
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

function CompanyMenu() {
  return (
    <>
    <div className='menupage'>
      <Container>
      <section className='heading'>
        <h1>Setup new devices:</h1>
      </section>
        <h4>CoreUnit</h4>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/cu/list'>
            <FaList /><div className='btn-text'>List</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/cu/add'>
            <FaPlus /><div className='btn-text'>Add new</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <h4>Node</h4>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/node/list'>
            <FaList /><div className='btn-text'>List</div>
          </Link>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row className='settingsButton'>
          <Link className={classNames('btn','btn-center')} to='/node/add'>
            <FaPlus /><div className='btn-text'>Add new</div>
          </Link>
        </Row>
      </Container>
    </div>
    </>
  )
}

export default CompanyMenu
