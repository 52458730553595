import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


class baseCRUDSlice {
  constructor(item_name, initialState) {
    this.item_name = item_name;    
    this.initialState = initialState
  }

  rejectOnError = (error, thunkAPI) => {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }

  // Create new item
  createItem = (serviceFunction) => {
    return createAsyncThunk(
      this.item_name + '/create',
      async (userData, thunkAPI) => {
        try {
          const authUser = thunkAPI.getState().auth.user
          const token = authUser.token
          const company_id = authUser.company_id
          return await serviceFunction(userData, token, company_id)
        } catch (error) {
          return this.rejectOnError(error, thunkAPI)
        }
      }
    )
  }

  // Get items
  getItems = (serviceFunction) => {
    return createAsyncThunk(
      this.item_name + '/getAll',
      async (_, thunkAPI) => {
        try {
          const authUser = thunkAPI.getState().auth.user
          const token = authUser.token
          const company_id = authUser.company_id
          return await serviceFunction(token, company_id)
        } catch (error) {
          return this.rejectOnError(error, thunkAPI)
        }
      }
    )
  }

  // Get item
  getItem = (serviceFunction) => {
    return createAsyncThunk(
      this.item_name + '/get',
      async (id, thunkAPI) => {
        try {
          const authUser = thunkAPI.getState().auth.user
          const token = authUser.token
          const company_id = authUser.company_id
          return await serviceFunction(id, token, company_id)
        } catch (error) {
          return this.rejectOnError(error, thunkAPI)
        }
      }
    )
  }

  // Delete item
  deleteItem = (serviceFunction) => {
    return createAsyncThunk(
      this.item_name + '/delete',
      async (id, thunkAPI) => {
        try {
          const authUser = thunkAPI.getState().auth.user
          const token = authUser.token
          const company_id = authUser.company_id
          return await serviceFunction(id, token, company_id)
        } catch (error) {
          return this.rejectOnError(error, thunkAPI)
        }
      }
    )
  }
  // Edit item
  editItem = (serviceFunction) => {
    return createAsyncThunk(
      this.item_name + '/edit',
      async (user, thunkAPI) => {
        try {
          const authUser = thunkAPI.getState().auth.user
          const token = authUser.token
          const company_id = authUser.company_id
          return await serviceFunction(user, token, company_id)
        } catch (error) {
          return this.rejectOnError(error, thunkAPI)
        }
      }
    )
  }
  // Logout
  clearItem = (serviceFunction) => {
    return createAsyncThunk(this.item_name + '/logout', async () => {
      console.log('baseliceba bejöttünk')
      await serviceFunction
    })
  }

  itemSlice = (state_item, createItem, getItems, getItem, editItem, deleteItem, clearItem) => {
    return createSlice({
      name: state_item,
      initialState: this.initialState,
      reducers: {
        reset: (state) => {
          state.isLoading = false
          state.isSuccess = false
          state.isError = false
          state.message = ''
        },
      },
      extraReducers: (builder) => {
        if(createItem){
          builder
            .addCase(createItem.pending, (state) => {
              state.isSuccess = false
              state.isLoading = true
            })
            .addCase(createItem.fulfilled, (state, action) => {
              state.isLoading = false
              state.isSuccess = true
              if(Array.isArray(state[state_item])){
                state[state_item].push(action.payload)
              } else {
                state[state_item] = action.payload
              }
            })
            .addCase(createItem.rejected, (state, action) => {
              state.isLoading = false
              state.isError = true
              state.message = action.payload
            })
        }
        if(editItem){
          builder
            .addCase(editItem.pending, (state) => {
              state.isSuccess = false
              state.isLoading = true
            })
            .addCase(editItem.fulfilled, (state, action) => {
              state.isLoading = false
              state.isSuccess = true
              state[state_item] = state[state_item].map(
                (item) => { return item.id === action.payload.id ? action.payload : item })
            })
            .addCase(editItem.rejected, (state, action) => {
              state.isLoading = false
              state.isError = true
              state.message = action.payload
            })
        }
        if(getItems){
          builder
            .addCase(getItems.pending, (state) => {
              state.isLoading = true
            })
            .addCase(getItems.fulfilled, (state, action) => {
              state.isLoading = false
              state.isSuccess = true
              state[state_item] = action.payload
            })
            .addCase(getItems.rejected, (state, action) => {
              state.isLoading = false
              state.isError = true
              state.message = action.payload
            })
        }
        if(getItem){
          builder
           .addCase(getItem.pending, (state) => {
              state.isLoading = true
            })
            .addCase(getItem.fulfilled, (state, action) => {
              state.isLoading = false
              state.isSuccess = true
              state[state_item] = action.payload
            })
            .addCase(getItem.rejected, (state, action) => {
              state.isLoading = false
              state.isError = true
              state.message = action.payload
            })
        }
        if(deleteItem){
          builder
            .addCase(deleteItem.pending, (state) => {
              state.isLoading = true
            })
            .addCase(deleteItem.fulfilled, (state, action) => {
              state.isLoading = false
              state.isSuccess = true
              state[state_item] = state[state_item].filter(
                (item) => item.id !== action.payload.id
              )
            })
            .addCase(deleteItem.rejected, (state, action) => {
              state.isLoading = false
              state.isError = true
              state.message = action.payload
            })
        }      
        if(clearItem){
          builder
            .addCase(clearItem.rejected, (state, action) => {
              state.isLoading = false
              state.isError = true
              state.message = action.payload
            })
            .addCase(clearItem.pending, (state) => {
              state.isLoading = true
            })
            .addCase(clearItem.fulfilled, (state, action) => {
              state.isLoading = false
              state.isSuccess = true
              state[state_item] = null
            })
        }
      },
    })
  }
}

export default baseCRUDSlice
