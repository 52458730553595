import baseCRUDService from '../common/baseService'

const API_PATH = 'companies/:company_id/boat'

const baseService = new baseCRUDService(API_PATH)

// Create new boat
const createBoat = async (boatData, token, company_id, ...params) => {
  return baseService.createItem(boatData, token, '', company_id)
}

// Get boats
const getBoats = async (token, company_id, ...params) => {
  return baseService.getItems(token, 'all', company_id)
}

// Get boats
const getBoat = async (boatId, token, company_id, ...params) => {
  return baseService.getItems(boatId, token, '',  company_id)
}

// Delete boat
const deleteBoat = async (boatId, token, company_id, ...params) => {
  return baseService.deleteItem(boatId, token, '',  company_id)
}

// Edit boat
const editBoat = async (boat, token, company_id, ...params) => {
  return baseService.editItem(boat, token, '',  company_id)
}

const clear = async (...params) => {
  return baseService.clear()
}

const boatService = {
  createBoat,
  getBoats,
  getBoat,
  editBoat,
  deleteBoat,
  clear,
}

export default boatService
