import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FaWrench } from 'react-icons/fa'
import { createCUToken, validateCU, reset } from '../features/cu/cuSlice'
import { getBoats } from '../features/boats/boatSlice'
import Spinner from '../components/Spinner'
import ProgressSteps from '../components/common/ProgressSteps'


const LOCAL_QUERY_TIMEOUT = 1200
const LOCAL_CLOSE_TIMEOUT = 2500
const LOCAL_DETECT_TIMEOUT = 3500

let LOCAL_API_URL = 'http://localhost'
if(process.env.NODE_ENV === 'production'){
  LOCAL_API_URL = 'http://local.sailync.hu'
}

const windowFeatures = 'popup,left=100,top=100,width=200,height=200'
const query_url = LOCAL_API_URL + '/api/query_qr'
const assign_url = LOCAL_API_URL + '/api/assign_token'

const steps = [
  'Find local CoreUnit',
  'Submit form',
  'Token created',
  'Assign token',
  'CoreUnit Configured',
]

function CuConfigForm() {

  const [delayMultiplier, setdelayMultiplier] = useState(1)
  const [currentStep, setcurrentStep] = useState(0)
  const [currentStatus, setcurrentStatus] = useState(undefined)
  const [localCUFound, setlocalCUFound] = useState(false)
  const [searchLoading, setsearchLoading] = useState(false)
  const [initial, setinitial] = useState(true)
  const [assignedSuccess, setAssignedSuccess] = useState(false)
  const [searchButtonEnabled, setsearchButtonEnabled] = useState(true)
  const [submitButtonEnabled, setsubmitButtonEnabled] = useState(false)
  const [assignButtonEnabled, setassignButtonEnabled] = useState(false)
  

  const [formData, setFormData] = useState({
    cu_uid: '',
    boatname: '',
    token: '',
    company: '',
    id:'',
  })

  const { cu_uid,
          id,
          boatname,
          token,
          company,
           } = formData

  const dispatch = useDispatch()

  const { coreunits, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.coreunits
  )
  const { boats } = useSelector(
    (state) => state.boats
  )
  

  let timeout = undefined

  useEffect(() => {
    if(boats.length === 0){
      dispatch(getBoats())
    }
    setFormData({
      ...formData,
      boatname: boats.length > 0 ? boats[0].name : ''
  }, [])
  }, [boats])

  useEffect(() => {

    if (isError && localCUFound) {
      toast.error(message)
    }
    
    if (localCUFound && initial) {
      setsearchLoading(false)
      toast.success('CoreUnit found')
      setinitial(false)
      setdelayMultiplier(1)
    }

    if (assignedSuccess && currentStep === 3) {
      setassignButtonEnabled(false)
      toast.success('CoreUnit assigned')
      setcurrentStep(4)
      setdelayMultiplier(1)
      dispatch(validateCU(cu_uid))
    }

    if(isSuccess && coreunits.cu_uid && coreunits.token && !coreunits.message){
      toast.success('Token created')
      setFormData({
        ...formData,
        token: coreunits.token
      })
      setsubmitButtonEnabled(false)
      setassignButtonEnabled(true)
      setcurrentStep(3)
    }
    if(isSuccess && coreunits.success && currentStep === 4 && currentStatus !== 'finished'){
      toast.success('CoreUnit validated')
      setcurrentStatus('finish')
      setassignButtonEnabled(false)

    }
    dispatch(reset())
  }, [coreunits, localCUFound, assignedSuccess, isError, isSuccess, message, dispatch])


  window.addEventListener('message', event => {
    // Query QR uid messages
    if (event.origin === LOCAL_API_URL && JSON.parse(event.data).qr_uid) {
      setFormData({
        ...formData,
        cu_uid: JSON.parse(event.data).qr_uid
      })
      clearTimeout(timeout)
      setsearchButtonEnabled(false)
      setsubmitButtonEnabled(true)
      setlocalCUFound(true)
      setcurrentStep(1)
      setcurrentStatus(undefined)
    } 
    // Assign token messages
    else if (event.origin === LOCAL_API_URL && JSON.parse(event.data).message) {
      if(!assignedSuccess && JSON.parse(event.data).message === 'VPN config written to file'){
        clearTimeout(timeout)
        setAssignedSuccess(true)
        setcurrentStatus(undefined)
      }
    } 
  })
  
  const getLocalData = () =>{
    setsearchLoading(true)
    let opwindow = window.open(query_url,'LocalPopup', windowFeatures)
    setTimeout(() => {
      opwindow.postMessage('Query_QR', LOCAL_API_URL);
    }, delayMultiplier * LOCAL_QUERY_TIMEOUT)
    setTimeout(() => {
        opwindow.postMessage('Close', LOCAL_API_URL);
    }, delayMultiplier * LOCAL_CLOSE_TIMEOUT)
    timeout = setTimeout(() => {
        setdelayMultiplier(delayMultiplier + 1)
        opwindow.close()
        setsearchLoading(false)
        setcurrentStatus('error')
        toast.error( `No local CoreUnit found, 
                      make sure you connected it
                      to the internet and you
                      are connected to SailyncAP`)
    }, delayMultiplier * LOCAL_DETECT_TIMEOUT)
  }

  const assignToken = () =>{
    let opwindow = window.open(assign_url,'LocalPopup', windowFeatures)
    let msg = JSON.stringify({ type: 'Token', token: token, qr_uid: cu_uid})
    setTimeout(() => {
      opwindow.postMessage(msg, LOCAL_API_URL);
    }, delayMultiplier * LOCAL_QUERY_TIMEOUT)
    setTimeout(() => {
        let msg = JSON.stringify({ type: 'Check'})
        opwindow.postMessage(msg, LOCAL_API_URL);
    }, delayMultiplier * LOCAL_CLOSE_TIMEOUT)
    timeout = setTimeout(() => {
        setdelayMultiplier(delayMultiplier + 1)
        opwindow.close()
        setcurrentStatus('error')
        toast.error( `Token assignment failed`)
      }, delayMultiplier * LOCAL_DETECT_TIMEOUT)
    }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const cuData = {
      cu_uid,
      boatname,
      company,
    }
    dispatch(createCUToken(cuData))
    setcurrentStep(2)
  }

  if (isLoading && localCUFound) {
    return <Spinner message={'Configuration in progress...'}/>
  }
  if (searchLoading && !localCUFound) {
    return <Spinner message={'Searching local CoreUnit...'}/>
  }

  return (
    <>
      <section className='heading'>
        <h1>
          <FaWrench /> Config CoreUnit
        </h1>
      </section>
      <section className='form'>
      <div className='form-group'>
        <button 
          id='initiate_query' 
          className='btn btn-block' 
          onClick={() => getLocalData()}
          disabled={!searchButtonEnabled}
          >
          Search for local CoreUnit
        </button>
      </div>
        <form onSubmit={onSubmit}>
          <div className='form-group'>
            <input
              type='text'
              disabled
              readOnly
              className='form-control'
              id='cu_uid'
              name='cu_uid'
              placeholder='Locally fetched QR code will appear here'
              value={cu_uid}
            />
          </div>
          {/* <label htmlFor='param'>Type arbitrary param:</label>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='boatname'
              name='boatname'
              value={boatname}
              placeholder='Add boatname'
              onChange={onChange}
            />
          </div> */}
          <div className='form-group'>
          <label htmlFor='boatname'>Select boat to assign:</label>
          {boats.length === 0 ?
            <input
              type='text'
              disabled
              readOnly
              className='form-control'
              placeholder='Please make sure you have boats created'
            /> :
            <select 
              name='boatname'
              id='boatname'
              className='form-control'
              onChange={onChange}
              value={boatname}
              >
              {boats.map((boat) => (
                <option key={boat.id} value={boat.name}>{boat.name}</option>
              ))}
            </select>
            }
          </div>
           
         <div className='form-group'>
            <button 
              id='submitButton' 
              type='submit' 
              className='btn btn-block'
              disabled={!submitButtonEnabled}
              >
              Submit
            </button>
            </div>
        </form>
        <div className='form-group'>
          <button 
            id='initiate_query'
            className='btn btn-block'
            onClick={() => assignToken()}
            disabled={!assignButtonEnabled}
            >
            Assign Token
          </button>
        </div>
          {/* <div className='form-group'>
              <button className='btn btn-block' onClick={() => dispatch(validateCU(cu_uid))}>
                Validate CU
              </button>
          </div> */}
        <ProgressSteps steps={steps} current={currentStep} currentStatus={currentStatus}/>
      </section>
    </>
  )
}

export default CuConfigForm
