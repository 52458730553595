import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Appbar from './components/Appbar'
import BoatDashboard from './pages/BoatDashboard'
import Users from './pages/Users'
import Login from './pages/Login'
import UserForm from './pages/UserForm'
import CompanyForm from './pages/CompanyForm'
import BoatForm from './pages/BoatForm'
import SetupMenu from './pages/SetupMenu'
import NodeConfigForm from './pages/NodeConfigForm'
import CuConfigForm from './pages/CuConfigForm'
import UserMenu from './pages/UserMenu'
import CompanyMenu from './pages/CompanyMenu'
import CompanyList from './pages/CompanyList'
import AdminMenu from './pages/AdminMenu'
import 'bootstrap/dist/css/bootstrap.min.css';
import BoatStatus from './components/BoatStatus'

function App() {
  return (
    <>  
      <div className="parent">
      <Router>
        <div className='content'>
          <Header />
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/boats' element={<BoatDashboard />} />
            <Route path='/boats/add' element={<BoatForm />} />
            <Route path='/boats/:boat_id' element={<BoatForm />} />
            <Route path='/users' element={<Users />} />
            <Route path='/users/add' element={<UserForm />} />
            <Route path='/users/:user_id' element={<UserForm />} />
            <Route path='/company' element={<CompanyMenu />} />
            <Route path='/company/add' element={<CompanyForm />} />
            <Route path='/company/:company_id' element={<CompanyForm />} />
            <Route path='/company/list' element={<CompanyList />} />
            <Route path='/manufacture' element={<AdminMenu />} />
            <Route path='/setup' element={<SetupMenu />} />
            <Route path='/config_node' element={<NodeConfigForm />} />
            <Route path='/config_cu' element={<CuConfigForm />} />
            <Route path='/settings' element={<UserMenu />} />
            <Route path='/' element={<BoatStatus />} />
          </Routes>
        </div>
        <Appbar />
      </Router>
      <ToastContainer />
      </div>
    </>
  )
}

export default App
