import syncService from './syncService'
import baseCRUDSlice from '../common/baseSlice'

const ITEM_NAME = 'sync'

// Get sync from localStorage
const sync = JSON.parse(localStorage.getItem('sync'))

const initialState = {
  sync: sync ? sync : {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

const baseSlice = new baseCRUDSlice(ITEM_NAME, initialState)

// POST sync
export const syncBoat = baseSlice.createItem(syncService.syncBoat)

// Logout
export const clear = baseSlice.clearItem(syncService.clear)

export const syncSlice = baseSlice.itemSlice(
  ITEM_NAME,
  undefined,
  syncBoat,
  undefined,
  undefined,
  undefined,
  clear
)

export const { reset } = syncSlice.actions
export default syncSlice.reducer
