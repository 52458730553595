import baseCRUDService from '../common/baseService'

const API_PATH = 'influx/status'

const baseService = new baseCRUDService(API_PATH)

// Get boat statuses
const getStatus = async (token, company_id, ...params) => {
  return baseService.getItems(token)
}

const clear = async (...params) => {
  return baseService.clear()
}

const influxService = {
  getStatus,
  clear,
}

export default influxService
