import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import UserForm from '../components/UserForm'
import { logout } from '../features/auth/authSlice'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import { getCompanies, deleteCompany, reset } from '../features/companies/companySlice'
import ActionButton from '../components/common/ActionButton'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';


function Dashboard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { companies, isLoading, isError, message } = useSelector(
    (state) => state.companies
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (!user || message === 'Not authorized') {
      dispatch(logout())
      navigate('/login')
    }
    if (companies.length === 0 && !isError) {
      dispatch(getCompanies())
    }
    return () => {
      dispatch(reset())
    }

  }, [companies, navigate, isError, message, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
     <br/>
      <section className='heading'>
        <h1>Welcome {user && user.username}</h1>
      </section>
      <div style={{ position: 'auto !important'}}>
      <Table responsive="sm" >
        <thead>
          <tr>
          <th scope="col">id</th>
          <th scope="col">Name</th>
          <th scope="col">Shortname</th>
          <th scope="col">Manage</th>
          </tr>
        </thead>
        <tbody>
            {companies.map((company) => (
            <tr key={company.id}>
              <th scope="row">{company.id}</th>
              <td>{company.company_name}</td>
              <td>{company.company_shortname}</td>
              <td>
                <Row>
                  <Col>
                    <ActionButton key={company.id + "_1"} type='faPen' onClick={() => navigate(`/company/${company.id}`)} color='blue' />
                  </Col>
                  <Col>
                    <ActionButton key={company.id + "_2"} type='faTrashCan' onClick={() => dispatch(deleteCompany(company.id))} color='red' />
                  </Col>
                </Row>
              </td>
            </tr>
            ))}
        </tbody>
        </Table>
        </div>
    </>
  )
}

export default Dashboard
