import { FaSignInAlt } from 'react-icons/fa'
import FormLogin from '../components/auth/FormLogin'
// import SCard from '../components/common/SCard'
// import Container from 'react-bootstrap/Container';volume
// import Row from 'react-bootstrap/Row';
// import { FaShip, FaWatchmanMonitoring } from 'react-icons/fa';
// import classNames from 'classnames';

function Login() {


  return (
    <>
      <section className='heading'>
        <h1>
          <FaSignInAlt/> Login
        </h1>
      </section>

      {/* <Container lg={12}>
          <Row>
            <SCard text='Users' link='/api/users'>
              <FaShip className='icon-button'/>
            </SCard>
            <SCard text='Masik' link='/api/users'>
              <FaWatchmanMonitoring className='icon-button'/>
            </SCard>
            <SCard text='Egyik' link='/api/users'>
              <FaWatchmanMonitoring className='icon-button'/>
            </SCard>
          </Row>
      </Container> */}

     <FormLogin />
    </>
  )
}

export default Login
