import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'
import { reset as resetUsers } from '../features/users/userSlice'
import HeaderDesktop from './common/HeaderDesktop'
import HeaderMobile from './common/HeaderMobile'

import MediaQuery from "react-responsive";

function Appbar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    dispatch(resetUsers())
    navigate('/')
  }

  return (
    <>
      <MediaQuery query="(max-width: 768px)">
            <HeaderMobile user={user} />

      </MediaQuery>
    </>
  )
}

export default Appbar
