import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaWrench } from 'react-icons/fa'
import { configNode, reset } from '../features/node/nodeSlice'
import {Html5Qrcode} from 'html5-qrcode'
import Spinner from '../components/Spinner'
import ProgressSteps from '../components/common/ProgressSteps'

const steps = [
  'Read Node QR Code',
  'Submit Form',
  'Configure Node',
  'Successful configuration',
]

function NodeConfigForm() {

  const [currentStep, setcurrentStep] = useState(0)
  const [currentStatus, setcurrentStatus] = useState(undefined)
  const [formData, setFormData] = useState({
    node_uid: '',
    param: '',
    // config: '',
  })

  const {  node_uid,
           param,
          //  config,
           } = formData

  const dispatch = useDispatch()

  const { nodes, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.nodes
  )

  const startReading = () => {
    let html5QrCode = new Html5Qrcode(/* element id */ "reader");
    Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
          html5QrCode.start(
            { 
              facingMode: "environment",
            },
            // cameraId,
            {
              fps: 10,    // Optional, frame per seconds for qr code scanning
              qrbox: { width: 350, height: 350 }  // Optional, if you want bounded box UI
            },
            (decodedText, decodedResult) => {
              // do something when code is read
              console.log(`Code matched = ${decodedText}`, decodedResult);
              setFormData((prevState) => ({
                ...prevState,
                node_uid: decodedText,
              }))
              setcurrentStep(1)
              setcurrentStatus(undefined)
              // savedCameraId = cameraId
              html5QrCode.stop().then((ignore) => {
                // QR Code scanning is stopped.
              }).catch((err) => {
                // Stop failed, handle it.
              });
            },
            (errorMessage) => {
              // parse error, ignore it.
              // console.log(errorMessage);
            })
          .catch((err) => {
            // Start failed, handle it.
            console.log(err);
          });
      }
    }).catch(err => {
      // handle err
    });


  }

  useEffect(() => {

    if (isError) {
      toast.error(message)
      setcurrentStatus('error')
    }
    
    if (isSuccess) {
      setcurrentStep(2)
      toast.success("Node assigned")
      setcurrentStep(3)
      setcurrentStatus('finish')
    }

    dispatch(reset())
  }, [nodes, isError, isSuccess, message, dispatch])


  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const nodeData = {
      node_uid,
      param
      // config,
    }
    dispatch(configNode(nodeData))
    
  }

  if (isLoading) {
    return <Spinner message={'Configuration in progress..'}/>
  }

  return (
    <>
      <section className='heading'>
        <h1>
          <FaWrench /> Config Node
        </h1>
      </section>
      <section className='form'>
      <div className='form-group'>
          <div id="reader" width="100%" height="100%"></div>
          <button className='btn btn-block' onClick={() => startReading()}>
              Read QR
            </button>
      </div>
        <form onSubmit={onSubmit}>
          {/* <div className='form-group'>
          <input type="file" accept="image/*" capture="camera">
          </input>
          </div>
          */}
          <div className='form-group'>
            <input
              type='text'
              disabled
              readOnly
              className='form-control'
              id='node_uid'
              name='node_uid'
              placeholder='Decoded QR code will appear here'
              value={node_uid}
            />
          </div>
          <label htmlFor="param">Type arbitrary param:</label>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              id='param'
              name='param'
              value={param}
              placeholder='Add an arbitrary parameter'
              onChange={onChange}
            />
          </div>
          {/* <div className='form-group'>
          <label htmlFor="role_id">Select Boat to assign:</label>
            <select 
              name="role_id"
              id="role_id"
              className='form-control'
              value={undefined}
              onChange={onChange}
              >
              <option value="10">Manufacture user</option>
              <option value="20">Company Admin</option>
              <option value="30">Normal user</option>
            </select>
          </div>
            */}
          <div className='form-group'> 
            <button type='submit' className='btn btn-block'>
              Submit
            </button>
          </div>
        </form>
        <ProgressSteps steps={steps} current={currentStep} currentStatus={currentStatus}/>
      </section>
    </>
  )
}

export default NodeConfigForm
