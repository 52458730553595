import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas as solidIcons } from '@fortawesome/free-solid-svg-icons';

function ActionButton({ type, color, onClick}) {
  return (
    <FontAwesomeIcon 
    className={'table-icon'}
    icon={solidIcons[type]} 
    style={{color: color}} 
    onClick={() => onClick()}
    />
  )
}

export default ActionButton